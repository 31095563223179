import React from 'react'
import Layout from '../../components/Layout'

const treelinks = [
  { name: "Treasurer's Report 2019", file: 'TreasurerRpt2019.pdf' },
  { name: "Treasurer's Report 2018", file: 'TreasurerRpt2018.pdf' },
  { name: "Treasurer's Report 2017", file: 'TreasurerRpt2017.pdf' },
  { name: "Treasurer's Report 2016", file: 'TreasurerRpt2016.pdf' },
  { name: "Treasurer's Report 2015", file: 'TreasurRpt2015.pdf' },
  { name: "Treasurer's Report 2014", file: 'TreasurerRpt2014.pdf' },
]

class Newsletters extends React.Component {
  render() {
    return (
      <Layout>
        <h3>Treasurer's Reports</h3>
        <ul>
          {treelinks.map(link => (
            <p key={link.name}>
              <a
                target="blank"
                href={`treasurersreports/${link.file}`}
                title={link.name}
              >
                {link.name.replace(/_/g, ' ').replace(/.pdf/g, '')}
              </a>
            </p>
          ))}
        </ul>
      </Layout>
    )
  }
}
export default Newsletters
